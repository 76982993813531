<template>
  <div class="action-button">
    <router-link v-if="type === 'internal' && link"
                 :to="{ name: link }"
    >
      <span v-if="text" class="text"> {{text}} </span>
      <img v-if="icon" :src="icon" :alt="`link to ${id}`">
    </router-link>

    <a v-else-if="link" :href="link" target="_blank">
      <span v-if="text" class="text"> {{text}} </span>
      <img v-if="icon" :src="icon" :alt="`link to ${id}`">
    </a>

    <div v-else>
      <span v-if="text" class="text"> {{text}} </span>
      <img v-if="icon" :src="icon" :alt="`link to ${id}`">
    </div>

  </div>
</template>

<script>
export default {
  name: 'SectionLink',
  props: {
    data: Object
  },
  computed: {
    id() {
      return this.data.id || ''
    },
    icon() {
      return this.data.icon ? require(`@/assets/icons/${this.data.icon}`) : ''
    },
    text() {
      return this.data.text || ''
    },
    linkData() {
      return this.data.link || {}
    },
    type() {
      return this.linkData ? this.linkData.type : ''
    },
    link() {
      return this.linkData ? this.linkData.url : ''
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.action-button {
  filter: brightness(90%);
}
.action-button:hover {
  cursor: pointer;
  filter: brightness(100%);
}
.action-button .text {
  transition: all 0.1s ease;
}
.action-button:hover .text {
  color: var(--lime-green);
}
.action-button img {
  height: 3rem;
}
</style>
