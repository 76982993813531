<template>
  <div class="home-section-title">

    <h2>{{text}}</h2>

  </div>
</template>

<script>
export default {
  name: 'SectionLink',
  props: {
    text: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.home-section-title {
  display: grid;
  grid-column: 1/2;
  grid-row: 1/2;
  align-content: center;
  text-align: center;
  z-index: 2;
  padding: 0 5%;
  height: 100%;
}
.home-section-title > * {
  font-size: 130%;
  font-weight: 900;
}

.home-content.section .home-link .home-section-title {
  grid-row: 1/3;
}

/* ---------------------------- tablet --------------------------------- */
@media only screen and (min-width: 600px) and (max-width: 1025px) {
  .home-section-title > * {
    font-size: 180%;
  }
}

/* ---------------------------- desktop -------------------------------- */
@media only screen and (min-width: 1025px) and (max-width: 2000px) {
  .home-section-title > * {
    font-size: 220%;
  }
}

/* ---------------- someone with too much money ------------------------ */
@media only screen and (min-width: 2000px) {
  .home-section-title > * {
    font-size: 270%;
  }
}
</style>
