<template>
  <div id="community" class="wrapper community page banner-page">

    <page-header img="banners/community.png"
                 title="OUR COMMUNITY"
    />

    <div class="page-content">
      <div class="intro">
        <p><b>The latest news and recent events from the Matisa Bicycles community. Ride on.</b></p>
      </div>
      <div id="gallery" class="gallery sec">
        <h3 class="intro-title">Gallery</h3>
        <gallery />
      </div>

      <div id="events" class="upcoming-events sec">
        <h3 class="intro-title">Upcoming Events</h3>
        <div class="events-list" v-if="upcomingEvents.length">
          <event-item v-for="event in upcomingEvents"
                     :key="event.title"
                     :data="event"
          />
        </div>
        <div v-else>
          <p>No upcoming events right now. Check back soon!</p>
        </div>
      </div>

      <div id="collaborators" class="collaborators sec">
        <h3 class="intro-title">We have worked with:</h3>
        <div class="collaborators-list">
          <a v-for="collaborator in collaborators"
             :href="collaborator.link"
             :key="collaborator.name"
             target="_blank"
             class="collaborator"
          >
            <img :src="require(`@/assets/icons/collaborators/${collaborator.logo}`)"
                 :alt="`${collaborator.name} logo`"
            />
            <h5>{{ collaborator.name }}</h5>
          </a>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import PageHeader from '@/components/global/PageHeader.vue'
import Gallery from '@/components/community/Gallery.vue'
import EventItem from '@/components/community/EventItem.vue'

export default {
  name: 'Community',
  components: {
    PageHeader,
    Gallery,
    EventItem,
  },
  computed: {
    ...mapGetters({
      'upcomingEvents': 'getUpcomingEvents',
      'collaborators': 'getCollaborators',
    }),
  },
  methods: {
    ...mapActions(['getInstaRssFeed', 'getUpcomingEventsGapi'])
  },
  mounted() {
    this.getInstaRssFeed()
    this.getUpcomingEventsGapi()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
p {
  padding-right: 15%;
}
.intro {
  display: grid;
  grid-gap: 2rem;
}
.page-content .sec {
  display: grid;
  grid-gap: 2rem;
  margin-bottom: 3rem;
}
.page-content .sec:last-child {
  margin-bottom: 0;
}

/* events--------------------------- */
.events-list {
  display: grid;
  grid-gap: 1rem;
}

/* collaborators --------------------------- */
.collaborators-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
}
.collaborator {
  display: inline-block;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: rgba(255,255,255,0.05);
  display: grid;
  align-content: center;
  justify-items: center;
  grid-gap: 1rem;
  width: auto;
  margin-bottom: 1rem;
  transition: all 0.25s ease;
}
.collaborator:hover {
  background: rgba(255,255,255,0.08);
}

/* ---------------------------- mobile --------------------------------- */
@media only screen and (max-width: 600px) {

}

/* ---------------------------- tablet --------------------------------- */
@media only screen and (min-width: 600px) and (max-width: 1025px) {
  /* .collaborators-list {
    grid-template-columns: 1fr 1fr;
  } */
}

/* ---------------------------- desktop -------------------------------- */
@media only screen and (min-width: 1025px) {
  .intro p {
    padding-right: 35%;
  }
  /* .collaborators-list {
    grid-template-columns: 1fr 1fr 1fr;
  } */
}
</style>
