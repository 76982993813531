<template>
  <div class="page-header">

      <img v-if="image" class="banner" :src="image" alt="header banner"/>
      <h1 class="page-title">{{title}}</h1>

  </div>
</template>

<script>

export default {
  name: 'PageHeader',
  props: {
    img: String,
    title: String
  },
  computed: {
    image() {
      return this.img ? require(`@/assets/images/${this.img}`) : ''
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.page-header {
  height: 35vh;
  overflow: hidden;
  display: grid;
}
.page-header::after {
  grid-column: 1/2;
  grid-row: 1/2;
  width: 100%;
  height: 35vh;
  content: 'i';
  color: transparent;
  background: var(--dark-green);
  opacity: 0.8;
  mix-blend-mode: color;
}
.banner {
  height: 35vh;
  width: 100%;
  /* opacity: 0.8; */
  object-fit: cover;
  object-position: 50% 20%;
  filter: brightness(70%);
}
.contact.page .banner {
  object-position: 50% 50% !important;
}
.page-title, .banner {
  grid-column: 1/2;
  grid-row: 1/2;
}
.page-title {
  display: grid;
  justify-self: center;
  align-items: center;
  height: 100%;
  font-weight: 900;
  font-size: 230%;
  z-index: 2;
}
</style>
