<template>
  <div class="gallery-item-container"
       @click="openPostHandler"
  >
    <div class="gallery-item">
      <iframe :src="image" v-if="type === 'VIDEO' && image" />
      <img :src="image" :alt="title"
           v-else-if="image"
           @error="imageErrorHandler"
      />
      <span>{{ text }}</span>
    </div>
    <p class="caption">{{ cappedTitle }}</p>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'GalleryItem',
  props: {
    data: Object,
    text: String,
    lightbox: Boolean
  },
  computed: {
    image() {
      return this.data.image || ''
    },
    title() {
      return this.data.title || ''
    },
    cappedTitle() {
      let maxWords = 14
      let words = this.title.split(' ')
      let cappedTitle = ''
      if(words.length > maxWords) {
        words.forEach((word,index) => {
          if(index < maxWords) {
            cappedTitle+= `${word} `
          }
        })
        cappedTitle = cappedTitle.trim()
        cappedTitle+= '...'
      } else {
        cappedTitle = this.title
      }
      return cappedTitle
    },
    link() {
      return this.data.link  || ''
    },
    type() {
      return this.data.type  || 'image'
    }
  },
  methods: {
    ...mapActions(
      ['openLightboxPost']
    ),
    openPostHandler() {
      if(this.lightbox) {
        this.openLightboxPost(`${this.link}embed`)
      } else {
        window.open(this.link,'_blank')
      }
    },
    imageErrorHandler() {
      this.$store.dispatch('removeGalleryPost', this.link)
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.gallery-item-container {
  display: grid;
  grid-gap: 1rem;
}
#gallery .slider .gallery-item {
  transition: filter 0.2s ease;
  display: grid;
  cursor: pointer;
}
#gallery .slider .gallery-item:hover {
  filter: brightness(80%);
}
#gallery .slider .gallery-item > * {
  grid-column: 1/2;
  grid-row: 1/2;
}
#gallery .slider .gallery-item > span {
  align-self: center;
  justify-self: center;
  font-size: 120%;
  font-weight: bold;
  color: white;
}
#gallery .slider .gallery-item.more {
  background: black;
}
#gallery .slider .gallery-item:not(.more) > span {
  visibility: hidden;
}
#gallery .slider .gallery-item:hover > span {
  visibility: visible;
}
  #gallery .slider img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

iframe {
  width: 100%;
  height: 100%;
}

.caption {
  height: 4rem;
}
</style>
