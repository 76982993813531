<template>
  <div id="about" class="wrapper about page">

    <!-- what we do / services -->
    <!-- fitting, custom design, building, painting, repairs -->

    <!-- where we are -->

    <!-- why/when - background/experience -->

    <!-- how - link to workshop tour -->

    <page-header img="banners/about.png"
                 title="ABOUT US"
    />

    <div class="page-content">

      <h3 class="page-title">Matisa Bicycles</h3>
      <div class="intro">
        <div class="intro-text">
          <p><b>We build one of a kind custom built steel frames, designed around you. Our bespoke builds are hand crafted in Brisbane, Australia.</b></p>
          <p><b>We provide fitting, custom frame design, frame building and painting services. Bike repairs are also welcome at our workshop in Virginia.</b></p>
        </div>
      </div>

      <div class="services-details">
        <h5 class="sub-title">Our Services</h5>
        <p>FITTING | CUSTOM DESIGN | BUILDING | PAINTING | REPAIRS</p>
      </div>

      <hr>

      <div class="experience-details">
        <h5 class="sub-title">Experience</h5>
        <p>Trained in Birmingham, UK with over 30 years of specialist experience in designing and building bike frames, Matthew Norman has gained a unique insight into the process of creating custom bikes. With an extensive history in working with all types of bike - from Road Touring to Track and Gravel - and as a qualified GURU Academy fitter, Matthew draws from his broad engineering background to create bespoke solutions for any cyclist.</p>
      </div>

      <div class="history-images">
        <img alt="cycling race" src="@/assets/images/about/931.png" />
        <img alt="venom bike frame" src="@/assets/images/about/venom.png" />
        <img alt="cycling race jersey" src="@/assets/images/about/fitting.png" />
      </div>

    </div>

  </div>
</template>

<script>
import PageHeader from '@/components/global/PageHeader.vue'
export default {
  name: 'About',
  components: {
    PageHeader,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page {
  background-image: linear-gradient(176deg, var(--background-colour) 8%, #383838 65%, #727272 70%, #383838 81%,var(--background-colour) 95%);
}
.page-title {
  padding-top: 2rem;
}
.intro-text {
  display: grid;
  grid-gap: 1rem;
}

.history-images {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  justify-items: center;
  max-width: 100%;
}
.history-images img {
  width: 100%;
  object-fit: cover;
}
.experience-details {
  margin-top: 1rem;
}

/* ---------------------------- desktop -------------------------------- */
@media only screen and (min-width: 1025px) {
  .page-content {
    grid-template-columns: 2fr 1fr;
  }
  hr {
    grid-column: span 2;
  }
  .page-title {
    grid-column: span 2;
  }
  .services-details {
    grid-column: 1/2;
  }
  .history-images {
    grid-column: span 2;
  }
}
</style>
