<template>
  <div id="contact" class="wrapper contact page">

    <page-header img="banners/contact.png"
                 title="CONTACT US"
    />

    <div class="page-content">

      <h3 class="intro-title">Matisa Bicycles</h3>
      <div class="intro">
        <div class="intro-text">
          <p><b>Feel free to get in touch with us for additional info or if you have any questions.</b></p>
          <p><b>We are based in Brisbane, Australia, find our location below.</b></p>
        </div>

        <div class="profile-details">
          <img class="profile" src="@/assets/icons/matt_profile.png" alt="Photo of Matt Norman"/>
          <h5>Matt Norman</h5>
          <p>Engineer</p>
        </div>

        <div class="contact-details">
          <h5>Call Us:</h5>
          <p>+61 0415 237 186</p>
          <h5>Email Us:</h5>
          <p><b>Technical Advice:</b> matt@matisabicycles.com <br> <b>General Enquiries & Customer Service:</b> info@matisabicycles.com</p>
          <h5>Visit Us:</h5>
          <router-link :to="{ name: 'book-a-fitting' }">Click here to book an appointment</router-link>
        </div>
      </div>

      <hr>

      <h5 class="location-title">Where to find us:</h5>
      <div class="location-map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14172.127839352568!2d153.04969784380253!3d-27.374717272028665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b93e2864c7337bb%3A0xd4c53d60cb2a7abb!2s58%20Pritchard%20Rd%2C%20Virginia%20QLD%204014%2C%20Australia!5e0!3m2!1sen!2sgg!4v1610379288668!5m2!1sen!2sgg" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
      </div>

      <div class="location-details">
        <div class="address">
          <p>Shop 6</p>
          <p>58 Prichard Road</p>
          <p>Virginia</p>
          <p>QLD 4014</p>
          <p>AUSTRALIA</p>
        </div>
        <div class="opening-times">
          <h5>Opening Times:</h5>
          <p>Get in touch to book an appointment</p>
        </div>
      </div>
      <!-- google location: Shop 6, 58 Prichard Rd, Virginia, QLD, 4014, AU -->

    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/global/PageHeader.vue'
export default {
  name: 'Contact',
  components: {
    PageHeader,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .page {
    /* display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    padding-bottom: 5rem; */

    background-image: linear-gradient(176deg, var(--background-colour) 8%, #383838 65%, #727272 70%, #383838 81%,var(--background-colour) 95%);
  }
  .intro-title {
    padding-top: 2rem;
  }
  .intro {
    display: grid;
    grid-gap: 4rem;
  }
  .intro-text {
    display: grid;
    grid-gap: 1rem;
    align-content: flex-start;
  }
  .intro .contact-details {
    display: grid;
    align-content: flex-end;
    grid-gap: 0.2rem;
  }
  .intro .contact-details p {
    margin-bottom: 1rem;
  }
  .intro .contact-details a {
    margin-top: 0.15rem;
  }

  .profile-details {
    display: grid;
  }
  .profile-details .profile {
    height: 7rem;
    width: 7rem;
    margin-bottom: 1.5rem;
  }

  .location-details {
    display: grid;
    grid-gap: 6rem;
    margin-top: 1rem;
  }
  .location-details h5 {
    margin-bottom: 0.15rem;
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  /* ---------------------------- desktop -------------------------------- */
  @media only screen and (min-width: 1025px) {
    .page-content {
      grid-template-columns: 2fr 1fr;
    }
    .intro-title {
      grid-column: span 2;
    }
    .intro {
      grid-gap: 1rem;
      grid-column: span 2;
      grid-template-columns: auto auto;
    }
    .profile-details {
      justify-content: flex-end;
      text-align: right;
      align-content: flex-start;
    }
    .profile-details .profile {
      justify-self: flex-end;
    }
    hr {
      grid-column: span 2;
    }
    .location-title {
      grid-column: span 2;
    }
    .location-details {
      text-align: right;
      margin-top: 0rem;
    }
    .location-details .opening-times {
      align-self: flex-end;
    }
  }
</style>
