<template>
  <div class="home-gallery">
    <div class="overlay">
      <img class="arrow left" @click="plusSlides(-1)" src="@/assets/icons/arrow-hover.svg">
      <div class="gallery-text">
        <home-section-title :text="text" />
      </div>
      <img class="arrow right" @click="plusSlides(1);" src="@/assets/icons/arrow-hover.svg">
    </div>
    <img v-if="currentImage"
         class="gallery backgrnd"
         :src="currentImage"
         :alt="`${galleryFolder} image ${currentIndex + 1}`"
    />

    <section-link v-if="linkData" :data="linkData"/>

  </div>
</template>

<script>
import SectionLink from '@/components/home/SectionLink.vue'
import HomeSectionTitle from '@/components/home/HomeSectionTitle.vue'

export default {
  name: 'HomeGallerySection',
  components: {
    SectionLink,
    HomeSectionTitle
  },
  props: {
    data: Object
  },
  data() {
    return {
      images: [],
      currentIndex: 0
    }
  },
  computed: {
    galleryFolder() {
      return this.data.folder || ''
    },
    text() {
      return this.data.text || ''
    },
    linkData() {
      return this.data.link
    },
    imageDirs() {
      return this.data.images
    },
    currentImage() {
      return require(`@/assets/images/${this.galleryFolder}/${this.imageDirs[this.currentIndex]}`)
    }
  },
  methods: {
    plusSlides(dir) {
      let newIndex = this.currentIndex += dir
      if(newIndex > this.imageDirs.length-1) {
        newIndex = 0
      } else if(newIndex < 0) [
        newIndex = this.imageDirs.length-1
      ]
      this.currentIndex = newIndex
    }
  },
  mounted() {
    // this.loadImages()
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.home-gallery {
  display: grid;
  grid-template-rows: 90% 10%;
  grid-template-columns: 1fr;
  overflow: hidden;
}
.home-gallery > * {
  grid-column: 1/2;
  grid-row: 1/3;
}
.overlay {
  display: grid;
  grid-template-columns: 2rem auto 2rem;
  grid-gap: 0.5rem;
  align-items: center;
  z-index: 2;
  padding: 0 2rem;
}
.gallery {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gallery-text {
  text-align: center;
  align-self: center;
  /* justify-self: center; */
  z-index: 2;
}
.arrow {
  height: 2rem;
  width: auto;
  align-self: center;
  margin-top: -1vh;
  filter: brightness(1000%);
  transition: filter 0.2s ease;
}
.arrow:hover {
  filter: brightness(100%);
}
.right {
  justify-self: flex-end;
}
.left {
  justify-self: flex-start;
  transform: rotate(180deg);
}

.section-link {
  grid-row: 2/3 !important;
}

/* ---------------------------- tablet --------------------------------- */
@media only screen and (min-width: 600px) and (max-width: 1025px) {
  .overlay {
    grid-gap: 1rem;
    grid-template-columns: 3rem auto 3rem;
  }
  .arrow {
    height: 3rem;
  }
}

/* ---------------------------- desktop -------------------------------- */
@media only screen and (min-width: 1025px) and (max-width: 2000px) {
  .overlay {
    grid-gap: 2rem;
    grid-template-columns: 4rem auto 4rem;
  }
  .arrow {
    height: 4rem;
  }
}

/* ---------------- someone with too much money ------------------------ */
@media only screen and (min-width: 2000px) {
  .overlay {
    grid-gap: 3rem;
    grid-template-columns: 6rem auto 6rem;
  }
  .arrow {
    height: 6rem;
  }
}
</style>
