<template>

  <div id="feature"
       class="home-content intro"
       :class="orientation"
  >
    <img class="backgrnd" src="@/assets/images/global/web-header.png">
    <div class="text center">
      <img src="@/assets/icons/matisa-logo_lime.png"
           alt="MATISA"
      />
      <h2 class="moto">MADE FOR YOU</h2>
    </div>
  </div>

</template>

<script>
export default {
  name: 'Feature',
  data() {
    return {
      orientation: 'landscape',
    }
  },
  methods: {
    calcOrientation() {
      this.orientation = window.innerWidth > window.innerHeight ? 'landscape' : 'portrait'
    }
  },
  mounted() {
    this.calcOrientation()
    window.addEventListener('resize', this.calcOrientation)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calcOrientation)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.intro {
  grid-column: 1/2;
  grid-row: 1/2;
  display: grid;
}
.backgrnd {
  grid-column: 1/2;
  grid-row: 1/2;
  width: 100%;
  height: 100%;
  opacity: 0.95;
  object-fit: cover;
}
.center {
  grid-column: 1/2;
  grid-row: 1/2;
  align-self: center;
  padding: 1.3rem;
  width:55%;
  opacity: 0.95;
}
.portrait .center {
  width: 70%;
}
.text {
  display: grid;
  grid-gap: 0.5rem;
}
.text .moto {
  font-size: 100%;
  font-weight: 900;
}
.badge-background {
  grid-column: 1/2;
  grid-row: 1/2;
  align-self: flex-end;
  justify-self: flex-end;
  margin-bottom: 5rem;
  margin-right: -2.4%;
  width: 45%;
  opacity: 0.75;
  filter: blur(0.4px);
  filter: drop-shadow(2px 2px 3px grey) invert(5%);
}
.portrait .badge-background {
  width: 70%;
  margin-bottom: 5rem;
}

/* ---------------------------- tablet --------------------------------- */
@media only screen and (min-width: 600px) and (max-width: 1025px) {
  .text .moto {
    font-size: 150%;
  }
}
/* ---------------------------- desktop -------------------------------- */
@media only screen and (min-width: 1025px) and (max-width: 2000px) {
  .text .moto {
    font-size: 250%;
  }
}
/* ---------------- someone with too much money ------------------------ */
@media only screen and (min-width: 2000px) {
  .text .moto {
    font-size: 300%;
  }
}
</style>
