<template>
  <div class="home-call-to-action">

    <home-section-title :text="text" />
    <img v-if="bgImage"
         class="backgrnd"
         :src="bgImage"
    />

    <div class="buttons" v-if="buttons">
      <action-button v-for="buttonData in buttons"
                   :data="buttonData"
                   :key="buttonData.id"
                   :id="buttonData.id"
      />
    </div>

  </div>
</template>

<script>
import ActionButton from '@/components/home/ActionButton.vue'
import HomeSectionTitle from '@/components/home/HomeSectionTitle.vue'

export default {
  name: 'HomeCallToActionSection',
  components: {
    ActionButton,
    HomeSectionTitle,
  },
  props: {
    data: Object
  },
  computed: {
    bgImage() {
      return this.data.img ? require(`@/assets/images/${this.data.img}`) : ''
    },
    text() {
      return this.data.text || ''
    },
    buttons() {
      return this.data.buttons || []
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.home-call-to-action {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr;
  align-content: center;
  grid-gap: 4rem;
}
.home-call-to-action .backgrnd {
  grid-column: 1/2;
  grid-row: 1/3;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* align-content: flex-end; */
}
.home-call-to-action .home-section-title {
  align-content: flex-end;
}
.buttons {
  grid-column: 1/2;
  grid-row: 2/3;
  display: grid;
  grid-auto-flow: column;
  max-width: 100%;
  overflow: hidden;
  align-content: flex-start;
  align-items: center;
  justify-content: center;
  grid-gap: 1.5rem;
}
</style>
