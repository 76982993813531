<template>
  <div id="gallery">

    <div class="slider" :style="galleryStyles" >
      <gallery-item v-for="(post,index) in showPosts"
         :key="index"
         :data="post"
         text="View full post"
         :lightbox="true"
      />
    </div>
    <div class="show-more-button"
         @click="increaseShowTotal"
    >
      <h4 v-if="!ifShowingAll">SHOW MORE</h4>
      <h4 v-else>SEE MORE ON INSTA</h4>
    </div>

    <gallery-lightbox v-if="currentLightboxPost" />

  </div>
</template>

<script>
import GalleryItem from '@/components/community/GalleryItem.vue'
import GalleryLightbox from '@/components/community/GalleryLightbox.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'Gallery',
  components: {
    GalleryItem,
    GalleryLightbox
  },
  data() {
    return {
      moreData: {
        link: "https://www.instagram.com/matisa_bicycles/"
      }
    }
  },
  computed: {
    ...mapGetters({
      'posts' : 'getGalleryPosts',
      'currentLightboxPost' : 'getCurrentLightboxPost',
      'pageWidth' : 'getPageWidth',
    }),
    showTotal() {
      return this.columns > 1 ? this.columns : 2
    },
    ifShowingAll() {
      return this.showTotal >= this.posts.length
    },
    showPosts() {
      return this.posts.slice(0, this.showTotal)
    },
    columns() {
      let columns = 1
      if(this.pageWidth > 600 && this.pageWidth <= 1025) {
        columns = 2
      } else if(this.pageWidth > 1025 && this.pageWidth <= 2000) {
        columns = 3
      }
      return columns
    },
    galleryStyles() {
      return {
        'grid-template-columns': `repeat(${this.columns}, 1fr)`
      }
    }
  },
  methods: {
    increaseShowTotal() {
      if(this.ifShowingAll) {
        window.open(this.moreData.link)
      } else {
        setTimeout(() => {
          this.showTotal+=this.columns
        }, 150)
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#gallery {
  display: grid;
  align-items: center;
  grid-gap: 1.5rem;
}
#gallery .slider {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;
  grid-row-gap: 2rem;
  padding-bottom: 0.4rem;
}
#gallery .slider::-webkit-scrollbar {
  height: 8px;
}
#gallery .slider::-webkit-scrollbar-thumb {
  border-radius: 1rem;
}
.show-more-button {
  justify-self: center;
  text-align: center;
  padding: 1rem;
  border: 2px solid var(--lime-green);
  cursor: pointer;
  transition: transform 0.2s ease;
}
.show-more-button:hover {
  background: var(--lime-green);
}
.show-more-button:active {
  transform: scale(0.97);
}
.show-more-button:hover > * {
  color: black;
}
</style>
