<template>
  <div class="gallery-lightbox"
       @click="closeLightboxHandler"
       :class="`${show ? 'show' : ''}`"
  >

    <div class="container" :class="`${loading ? 'loading' : ''}`" >
      <loading-iframe v-if="loading" />
      <div class="iframe-hugger">
          <iframe :src="currentLightboxPost"
                @load="setLoaded"
                :class="`${loading ? 'hidden' : ''}`"
                scrollTop="65px"
        />
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import LoadingIframe from '@/components/global/Loading.vue'

export default {
  name: 'GalleryLightbox',
  components: {
    LoadingIframe
  },
  data() {
    return {
      loading: true,
      show: false
    }
  },
  computed: {
    ...mapGetters({
      'currentLightboxPost' : 'getCurrentLightboxPost'
    }),
  },
  methods: {
    ...mapActions([
      'closeLightbox'
    ]),
    setLoaded() {
      this.loading = false
    },
    closeLightboxHandler() {
      this.show = false
      setTimeout(() => {
        this.closeLightbox()
      },100)
    }
  },
  mounted() {
    setTimeout(() => {
      this.show = true
    },50)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.gallery-lightbox {
  position: fixed;
  display: grid;
  top: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgba(0,0,0,0.7);
  transition: opacity 0.25s ease;
  opacity: 0;
}
.gallery-lightbox.show {
  opacity: 1;
}
.gallery-lightbox .container {
  align-self: center;
  height: 80vh;
  width: calc(80vh / (16/12.5));
  margin: auto;
}
.container div {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  background: white;
  box-shadow: 2px 2px 10px 2px rgba(0,0,0,0.4);
  border-radius: 3px;
  padding: 0 !important;
  overflow-x: hidden;
}
.container.loading .iframe-hugger {
  display: none;
  background: none;
}
.container iframe {
  width: 100%;
  height: 120vh;
  min-height: 100%;
  /* height: auto; */
  transition: opacity 0.25s ease;
}
.gallery-lightbox .container iframe::-webkit-scrollbar {
  width: 8px;
}
.gallery-lightbox .container iframe::-webkit-scrollbar-track {
  background: rgba(0,0,0,0.5);
  border-radius: 4px;
  padding: 1px;
}

/* square ish */
/* @media (min-aspect-ratio: 3/4) and (max-aspect-ratio: 4/3) {
  .gallery-lightbox .container {
    min-width: 55%;
  }
} */
/* tall ish */
/* @media (max-aspect-ratio: 100/1) and (min-aspect-ratio: 2/1) {
  .gallery-lightbox .container {
    min-width: 70%;
  }
} */
</style>
