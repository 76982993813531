<template>
  <div class="event-item">
    <div class="event-header">
      <h5 class="title">{{title}}</h5>
      <span v-if="startTime"><b>{{date}}</b></span>
    </div>
    <p class="description" v-if="description">{{description}}</p>
    <p class="location" v-if="location"><b>Location:</b> {{location}}</p>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'EventItem',
  props: {
    data: Object
  },
  computed: {
    title() {
      return this.data.title.toUpperCase()
    },
    startTime() {
      let startDate = new Date(this.data.startTime).toString()
      return startDate
    },
    endTime() {
      let endDate = new Date(this.data.endTime).toString()
      return endDate
    },
    date() {
      let dateAndTime = ''
      let startDate = moment(this.startTime).format("MMM Do")
      let endDate = moment(this.endTime).format("MMM Do")
      let startTime = moment(this.startTime).format('h:mm a')
      let endTime = moment(this.endTime).format('h:mm a')
      if(startDate === endDate) {
        //same day
        dateAndTime += `${startDate} - ${startTime} to ${endTime}`
      } else {
        dateAndTime += `${startDate} ${startTime} - ${endDate} ${endTime}`
      }
      return dateAndTime
    },
    description() {
      return this.data.description
    },
    location() {
      return this.data.location
    }
  },
  methods: {

  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.event-item {
  display: grid;
  grid-gap: 1.5rem;
  background: rgba(255,255,255,0.05);
  border-radius: 0.5rem;
  padding: 1rem;
}
.event-header {
  display: grid;
  grid-gap: 0.7rem;
  justify-content: flex-start;
  padding-bottom: 0.5rem;
}
.location {
  border-top: 1px solid rgba(255,255,255,0.2);
  padding-top: 1rem;
  opacity: 0.8;
}

</style>
