<template>
  <div class="home-link" >

    <home-section-title :text="text" />
    <img v-if="bgImage"
         class="backgrnd"
         :src="bgImage"
    />

    <section-link v-if="linkData" :data="linkData"/>

  </div>
</template>

<script>
import SectionLink from '@/components/home/SectionLink.vue'
import HomeSectionTitle from '@/components/home/HomeSectionTitle.vue'

export default {
  name: 'HomeLinkSection',
  components: {
    SectionLink,
    HomeSectionTitle
  },
  props: {
    data: Object
  },
  computed: {
    bgImage() {
      return this.data.img ? require(`@/assets/images/${this.data.img}`) : ''
    },
    text() {
      return this.data.text || ''
    },
    linkData() {
      return this.data.link
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.home-link {
  display: grid;
  grid-template-rows: 90% 10%;
  grid-template-columns: 1fr;
}
.home-link .backgrnd {
  grid-column: 1/2;
  grid-row: 1/3;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.home-link h1 {
  grid-column: 1/2;
  grid-row: 1/2;
  align-self: center;
  justify-self: center;
  text-align: center;
  z-index: 2;
}
</style>
