<template>
  <div id="home" class="wrapper home-page">

    <feature class="full" />
    <div v-for="sec in homeSections"
         class="home-content section"
         :key="sec.id"
         :id="sec.id"
         @click="(e) => navToPage(sec,e)"
         :class="`${ifPageLink(sec) ? 'link-to-page' : ''} ${sec.size}`"
    >
      <home-link-section v-if="sec.type === 'link'"
                         :data="sec"
                         :id="sec.id"
      />
      <home-gallery-section v-if="sec.type === 'gallery'"
                            :data="sec"
                            :id="sec.id"
      />
      <home-call-to-action-section v-if="sec.type === 'call-to-action'"
                                   :data="sec"
                                   :id="sec.id"
      />

    </div>


   </div>

</template>

<script>
import { mapGetters } from 'vuex'

import Feature from '@/components/home/Feature.vue'
import HomeLinkSection from '@/components/home/HomeLinkSection.vue'
import HomeGallerySection from '@/components/home/HomeGallerySection.vue'
import HomeCallToActionSection from '@/components/home/HomeCallToActionSection.vue'

export default {
  name: 'Home',
  components: {
    Feature,
    HomeLinkSection,
    HomeGallerySection,
    HomeCallToActionSection
  },
  computed: {
    ...mapGetters({
      'homeSections':'getHomeSections'
    }),
  },
  methods: {
    ifPageLink(sec) {
      return sec.link && sec.link.url && sec.link.type === 'internal'
    },
    navToPage(sec, e) {
      let ifPageLink = this.ifPageLink(sec)
      if(ifPageLink && !e.target?.classList?.contains('arrow')) {
        this.$router.push(sec.link.url)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.section {
  display: grid;
  overflow: hidden;
}
.section > * {
  height: 100%;
}
.full {
  height: 100vh;
}
.half {
  height: 80vh;
}

.home-content.link-to-page {
  cursor: pointer;
  transition: filter 0.25s ease;
}
.home-content.link-to-page:hover {
  filter: brightness(110%);
}

  /* ------------------------  */

</style>
