<template>
  <div id="fitting" class="wrapper fitting page banner-page">

    <page-header img="banners/fitting2.png"
                 title="BIKE FITTING"
    />

    <div class="page-content">
      <div class="intro">
        <h3 class="intro-title">Discover your perfect ride <br> with the GURU Fit System™</h3>
        <p><b>The GURU FIt System combines years of fit data and your real-time feedback to create the best possible riding position to match your riding style.</b></p>
      </div>

      <div class="what-is-guru-fit">
        <img src="@/assets/images/fitting/guru.jpg" alt="guru dynamic fit unit bike"/>
        <div>
          <p> The GURU Dynamic Fit Unit (DFU) is the only completely computer-aided fit bike. It allows for precise, real-time adjustments you can feel instantly in order to find your perfect riding position.</p>
          <p>Combined with advanced motion capture tools, we can compare unlimited numbers of riding positions specific to you.</p>
        </div>

        <img src="@/assets/images/banners/fitting2.png" alt="guru bike fitting"/>
        <div class="bottom">
          <p>We can tailor the bike fit to your cycling needs and goals, whether you would like to reduce hand pressure, improve your ‘aero’ position or create a better weight transfer. This includes trying out different saddle styles for a more comfortable ride.</p>

          <p>Once you have chosen your preferred riding position, we will record the key angles, lengths and recommended products in your fit report. This fit report is the key to greater comfort, efficiency and power in future cycling.</p>
        </div>

      </div>

      <div class="services">
        <h5 class="services-title">Our available services</h5>
        <div class="services-list">
          <div class="service-item"
               v-for="item in services"
               :key="item.id"
               :id="item.id"
          >
            <h4 class="title">{{item.name}} <span class="time"> [~ {{item.time}}]</span></h4>
            <p class="desc">{{item.desc}}</p>
            <p class="price"><b>{{item.price}}</b></p>
          </div>
        </div>
      </div>

      <div class="report-section">
        <h5 class="sample-report-title">Sample Report</h5>
        <div class="sample-report">
          <img src="@/assets/images/fitting/sample-fit-report2.png" alt="guru fit sample report"/>
          <img src="@/assets/images/fitting/sample-fit-report1.png" alt="guru fit sample report"/>
        </div>
      </div>
    </div>

    <router-link class="book-button"
                 :to="{ name: 'book-a-fitting' }"
    >
      <div>
        <h5>BOOK A FITTING</h5>
        <img src="@/assets/icons/arrow-hover.svg" alt="arrow" />
      </div>
    </router-link>

  </div>
</template>

<script>
import PageHeader from '@/components/global/PageHeader.vue'

export default {
  name: 'Fitting',
  components: {
    PageHeader
  },
  data: () => ({
    services: [
      {
        id: 'first',
        name: 'First bike fit',
        desc: 'For those new to cycling or those wanting to improve comfort on the bike. Includes seat bone measurement with saddle fit and foot pressure analysis.',
        time: '2 hours',
        price: '$200'
      },
      {
        id: 'review',
        name: 'Review bike fit',
        desc: 'Some time after first fit, when you want to make adjustments to increase performance and comfort after more time in the saddle.',
        time: '1 hour',
        price: '$100'
      },
      {
        id: 'purchase',
        name: 'Pre bike purchase size and fit',
        desc: 'Providing you advice on the right size and frame type for you.',
        time: '1 hour',
        price: '$120'
      },
      {
        id: 'pro',
        name: 'Advanced performance bike fit',
        desc: 'For those wanting to enhance your power, speed endurance and performance through optimising your position.',
        time: '3 hours',
        price: '$350'
      }
    ]
  }),
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
p {
  padding-right: 15%;
}
.intro {
  display: grid;
  padding: 4rem 0;
  grid-gap: 2rem;
}
.what-is-guru-fit {
  display: grid;
  grid-gap: 3rem;
}
.what-is-guru-fit div {
  display: grid;
  grid-gap: 1rem;
}

.opening-times, .services {
  display: grid;
  grid-gap: 1rem;
  padding: 2rem 0;
}
.opening-times .times-list, .services .services-list {
  background: var(--dark-green);
  padding: 1rem 1.5rem;
}
.services-title {
  padding-bottom: 1rem;
}
.services-list .service-item {
  display: grid;
  /* grid-template-columns: 1.5fr 1fr 1fr; */
  justify-content: flex-start;
  grid-gap: 2rem;
  padding: 2rem 0;
  border-bottom: 1px solid rgba(255,255,255, 0.4);
}
.services-list .service-item:first-child {
  padding-top: 1rem;
}
.services-list .service-item:last-child {
  border-bottom: none;
  padding-bottom: 1rem;
}
.services-list .service-item h4 {
  font-size: 130%;
}
.services-list .service-item .time {
  font-weight: normal !important;
  padding-left: 0.6rem;
}


.report-section {
  /* padding-top: 3rem; */
  display: grid;
  grid-gap: 2rem;
}
  .sample-report {
    display: grid;
    justify-self: center;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    max-width: 1000px;
    padding-bottom: 3rem;
  }
  .sample-report img {
    width: 100%;
  }

.book-button {
  position: fixed;
  right: calc(var(--page-padding) + 12px);
  bottom: calc(var(--page-padding) * 1.5);
  background: var(--lime-green);
  padding: 1rem 1.3rem;
  border-radius: 100rem;
  opacity: 0.95;
  box-shadow: 3px 3px 8px 1px rgba(0,0,0,0.5);
  transition: transform 0.2s ease;
}
.book-button div {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.7rem;
  align-items: center;
}
.book-button:hover {
  transform: scale(1.01);
}
.book-button h5 {
  color: black !important;
}
.book-button img {
  filter: brightness(0%);
  min-height: 1rem;
  height: 2rem;
  justify-self: flex-end;
  margin-bottom: 0.1rem;
}

/* ---------------------------- mobile --------------------------------- */
@media only screen and (max-width: 600px) {
  .book-button img {
    height: 1.2rem;
  }
}

/* ---------------------------- tablet --------------------------------- */
@media only screen and (min-width: 600px) and (max-width: 1025px) {
  .book-button img {
    height: 1.5rem;
  }
}

/* ---------------------------- desktop -------------------------------- */
@media only screen and (min-width: 1025px) {
  .page-content {
    grid-gap: 9rem;
  }
  .intro {
    padding-top: 9rem;
  }
  .intro p {
    padding-right: 35%;
  }
  .what-is-guru-fit {
    grid-template-columns: 1fr 1fr;
  }
  .what-is-guru-fit div {
    display: grid;
    grid-gap: 2rem;
    align-content: flex-start;
  }
  .what-is-guru-fit .bottom {
    grid-column: 1/2;
    grid-row: 2/3;
  }
}
</style>
