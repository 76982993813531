<template>
  <div id="fitting" class="wrapper book-a-fitting page">

    <!-- <h2>BOOK A FITTING</h2> -->
    <page-header img="banners/fitting.jpg"
                 title="BOOK A FITTING"
    />
    <div class="page-content">
      <p><b>Get in touch by filling out the form below to book a bike fit appointment at our workshop in Virginia, Brisbane. <br><br> Please see our available booking times below.</b></p>

      <div class="opening-times">
        <h5 class="opening-times-title">Our booking times:</h5>
        <div class="times-list">
          <p>Monday and Friday - 9am to 7pm</p>
          <p>Saturday and Sunday - 10am to 6pm</p>
        </div>
      </div>

      <div class="booking-form">
        <div v-for="(item,index) in formItems"
             class="form-item"
             :class="`${item.name} ${item.size} ${item.invalid ? 'invalid' : ''}`"
             :key="item.name"
        >
          <h5>{{ item.text }}</h5>
          <input v-if="item.el === 'input'"
                 :type="item.type"
                 :placeholder="item.placeholder"
                 v-model="formItems[index].value"
                 @input="checkIfFormValid"
          />
          <textarea v-if="item.el === 'textarea'"
                    :placeholder="item.placeholder"
                    v-model="formItems[index].value"
                    @input="checkIfFormValid"
          ></textarea>
        </div>
        <div class="submit"
             :class="{disabled: !ifValid}"
             @click="submitEmail"
        >
          <h5>{{submitText}}</h5>
        </div>
      </div>

      <hr>

      <div class="location-details">
        <h5 class="location-title">Where to find us:</h5>
        <div class="location-map">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14172.127839352568!2d153.04969784380253!3d-27.374717272028665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b93e2864c7337bb%3A0xd4c53d60cb2a7abb!2s58%20Pritchard%20Rd%2C%20Virginia%20QLD%204014%2C%20Australia!5e0!3m2!1sen!2sgg!4v1610379288668!5m2!1sen!2sgg" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        </div>

        <div class="address">
          <p>Shop 6</p>
          <p>58 Prichard Road</p>
          <p>Virginia</p>
          <p>QLD 4014</p>
          <p>AUSTRALIA</p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import PageHeader from '@/components/global/PageHeader.vue'

import { validateEmail } from '@/functions/utility.js'
import emailjs from 'emailjs-com';

export default {
  name: 'Fitting',
  components: {
    PageHeader
  },
  data() {
    return {
      formItems: [
        {
          name: 'name',
          text: 'Full Name',
          placeholder: 'Enter your name',
          el: 'input',
          type: 'text',
          size: 'half',
          value: '',
          invalid: false
        },
        {
          name: 'email',
          text: 'Email Address',
          placeholder: 'Enter your email address',
          el: 'input',
          type: 'email',
          size: 'half',
          value: '',
          invalid: false
        },
        {
          name: 'message',
          text: 'Message',
          placeholder: 'Please let us how you would like us to contact you to arrange a suitable time for your fit. We are happy to answer any questions you have.',
          el: 'textarea',
          type: 'text',
          size: 'full',
          value: '',
          invalid: false
        },
      ],
      submitText: 'SUBMIT EMAIL',
      ifValid: false,
    }
  },
  methods: {
    checkIfFormValid() {
      let valid = true
      this.formItems.forEach(item => {
        if(!item.value) {
          valid = false
          item.invalid = true
        } else if(item.name === 'email') {
          let validEmail = validateEmail(item.value)
          if(validEmail) {
            item.invalid = false
          } else {
            valid = false
            item.invalid = true
          }
        } else {
          item.invalid = false
        }
      })
      this.ifValid = valid
    },
    submitEmail() {
      if(!this.ifValid) return
      let data = {}
      this.formItems.forEach(item => {
        data[item.name] = item.value
      })
      try {
        emailjs.send('service_tkjp7z6', 'template_o2g5sr5', data,
        'user_R5x9fC7kfrH6fNXD6JcqQ')
      } catch(error) {
        alert('There may have been an error - please try again')
        console.log({error})
      }

      //reset form
      this.formItems.forEach(item => {
        item.value = ''
      })
      this.submitText = 'EMAIL SENT'
      setTimeout(() => {
        this.ifValid = false
        this.subitText = 'SUBMIT EMAIL'
      },800)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.opening-times, .services {
  display: grid;
  grid-gap: 1rem;
  padding: 2rem 0;
}
.opening-times .times-list, .services .services-list {
  background: var(--dark-green);
  padding: 1rem 1.5rem;
}

.booking-form {
  display: grid;
  grid-gap: 2rem;
  padding-bottom: 2rem;
}
.booking-form .form-item {
  display: grid;
  grid-gap: 0.7rem;
}
.form-item.invalid input, .form-item.invalid textarea {
  border: 1px solid red;
}
.booking-form input {
  max-width: 65%;
}
.booking-form textarea {
  resize: none;
  min-height: 4rem;
}
.booking-form input, .booking-form textarea {
  padding: 0.5rem;
  border-radius: 0.2rem;
  outline: none;
  border: none;
  font-family: 'EurostileBold', sans-serif;
}
.booking-form input::placeholder, .booking-form textarea::placeholder {
  font-style: italic;
}
.submit {
  background: var(--lime-green);
  padding: 0.5rem;
  border-radius: 0.2rem;
  color: black !important;
  justify-self: flex-start;
  box-shadow: 3px 3px 8px 1px rgba(0,0,0,0.5);
  opacity: 0.9;
  transition: opacity 0.2s ease;
}
.submit.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.submit:hover {
  opacity: 1;
  cursor: pointer;
}
.submit h5 {
  color: black !important;
}

.location-details {
  display: grid;
  grid-gap: 2rem;
  margin-top: 1rem;
}
.location-details iframe {
  min-height: 20rem;
}
.location-details h5 {
  margin-bottom: 0.15rem;
}

iframe {
  width: 100%;
  height: 100%;
}

/* ---------------------------- desktop -------------------------------- */
@media only screen and (min-width: 1025px) {
  .opening-times .times-list, .services .services-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5rem;
  }
  .services-list .service-item {
    grid-template-columns: auto auto auto;
    grid-gap: 3rem;
  }

  .booking-form {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 5rem;
  }
  .booking-form input {
    max-width: 100%;
  }
  .booking-form input, .booking-form textarea {
    padding: 0.7rem;
  }
  .booking-form .full {
    grid-column: span 2;
  }

  .location-title {
    grid-column: span 2;
  }
  .location-details {
    margin-top: 0rem;
  }
  .location-details > *:not(.location-title) {
    text-align: right;
  }
  .location-details .opening-times {
    align-self: flex-end;
  }
}
</style>
