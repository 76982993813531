<template>
  <div class="section-link">
    <div v-if="type === 'internal'"
         class="link"
    >
      <span class="text"> {{text}} </span>
      <img src="@/assets/icons/feature-link-icon.svg" alt="link to page">
    </div>

    <a v-else :href="link" target="_blank">
      <span class="text"> {{text}} </span>
      <img src="@/assets/icons/feature-link-icon.svg" alt="link to page">
    </a>

  </div>
</template>

<script>
export default {
  name: 'SectionLink',
  props: {
    data: Object
  },
  computed: {
    type() {
      return this.data.type || ''
    },
    link() {
      return this.data.url || ''
    },
    text() {
      return this.data.text || ''
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.section-link {
  grid-column: 1/2;
  grid-row: 2/3;
  /* align-self: flex-end;
  justify-self: flex-end; */
  padding-right: 2rem;
  height: 100%;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: flex-end;
  grid-gap: 0.5rem;
  font-size: var(--main-text);
  font-weight: bold;
  z-index: 2;
}
.section-link:hover {
  cursor: pointer;
}
.section-link .text {
  transition: all 0.1s ease;
}
.section-link:hover .text {
  color: var(--lime-green);
}
.section-link img {
  width: 1.25rem;
  filter: brightness(1000%);
}
.section-link:hover img {
  filter: brightness(100%);
}

</style>
